import Vue from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications);

Vue.prototype.$showGlobalNotification = function(title, text, type, reportId) {
  if (typeof reportId === 'undefined') {
    Vue.notify({
      group: 'main',
      title: title,
      text: text,
      type: type,
      speed: 1000,
      duration: 60000,
    });
  } else {
    const notificationKey = `notification_${reportId}`;
    const notificationFlags = JSON.parse(localStorage.getItem('notificationFlags')) || {};

    if (!notificationFlags[notificationKey]) {
      Vue.notify({
        group: 'main',
        title: title,
        text: text,
        type: type,
        speed: 1000,
        duration: 60000,
      });

      notificationFlags[notificationKey] = true;
      localStorage.setItem('notificationFlags', JSON.stringify(notificationFlags));
    }
  }
};
