

import isMob from "@/mixins/isMob";
export default {
  name: "header-navbar",
  computed: {
    headerMob() {
      return this.headerOptions.menuList.slice(1, this.headerOptions.menuList.length)
    },
    isUserLogged() {
      return this.$store.state.userModule.isLogged;
    }    
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
      // Отображение кнопки установки
      this.isShowInstallButton = true;
    });    

  },
  watch: {
    openMenu() {
      if (this.openMenu === false) {
        this.openSubMenu = false
      }
    }
  },
  mixins: [isMob],
  data() {
    return {

      deferredPrompt: null,
      isShowInstallButton: false,

      headerOptions: {
        menuList: [
          {
            item: "products",
            srcLink: "/products/",
            submenu: true
          },

          {
            item: "sample reports",
            srcLink: "/example/",
            submenu: false
          },

          {
            item: "our missions",
            srcLink: "/our-missions/",
            submenu: false
          },

          {
            item: "pricing",
            srcLink: "/products-services/",
            submenu: false
          },

          {
            item: "Testimonials",
            srcLink: "/testimonials/",
            submenu: false
          },

          {
            item: "About",
            srcLink: "/about-us/",
            submenu: false
          },

          {
            item: "Contact",
            srcLink: "/contact/",
            submenu: false
          },
        ],
        logoSrc:
          "https://ifindcheaters.com/",
        btnSrc: "",
        btnTitle: "sign in",
      },
      openMenu: false,
      openSubMenu: false,
      subMenu: false,
      dashMenu: false,
    }
  },
  methods: {
    submit() {
      this.$store.commit('userModule/LOGOUT')
      localStorage.removeItem('redirectUrl');
    },
    installApp() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            alert('The user has installed the application!');
          }
          this.deferredPrompt = null;
          // Скрытие кнопки установки после установки приложения
          this.isShowInstallButton = false;
        });
      }
    },    
    generateFullUrl(path) {
      return process.env.REDIRECT_URL + path;
    }    
  }
};
