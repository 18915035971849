export default {
    namespaced: true,
  
    state: () => ({
      searchName: '',
      searchEmail: '',
      searchState: '',
      searchAddress: '',
      searchPhone: '',
      searchCountryCode: '',

  
    }),
  
    getters: {
    },
  
    mutations: {
      SET_SEARCH_USER_NAME(state, value) {
        state.searchName = value;
      },
      SET_SEARCH_USER_EMAIL(state, value) {
        state.searchEmail = value;
      },
      SET_SEARCH_USER_STATE(state, value) {
        state.searchState = value;
      },
      SET_SEARCH_USER_ADDRESS(state, value) {
        state.searchAddress = value;
      },
      SET_SEARCH_USER_PHONE(state, value) {
        state.searchPhone = value;
      },
      SET_SEARCH_USER_COUNTRY_CODE(state, value) {
        state.searchCountryCode = value;
      },
      RESET_SEARCH_USER_DATA(state) {
        state.searchName = '';
        state.searchEmail = '';
        state.searchState = '';
        state.searchAddress = '';
        state.searchPhone = '';
        state.searchCountryCode = '';
      },
    },
  
    actions: {
    }
  };
  