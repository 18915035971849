

import Header from "@/components/Header";
import FooterMain from "@/components/FooterMain";
import AsideComponent from "@/components/dashboard/asideComponent.vue";
import AsideComponentMob from "@/components/dashboard/asideComponentMob.vue";
import authorization from "@/mixins/authorization";

export default {

  components: {Header, FooterMain, AsideComponent, AsideComponentMob},
  mixins: [authorization],
  data() {
    return {
      account: [
        {
          name: 'dashboard',
          lnk: '/account/dashboard/',

        },
        {
          name: 'search report',
          lnk: '/account/search-report/',

        },
        {
          name: 'profile',
          lnk: '/account/profile/',

        },
      ],

      planss: [
        {
          name: 'manage plan',
          lnk: '/account/subscriptions/manage/',

        },
        {
          name: 'cancel billing',
          lnk: '/account/billing/cancel/',

        },
        {
          name: 'notification setting',
          lnk: '/account/notifications/',

        },
      ],

      credits: [
        {
          name: 'search credits',
          lnk: '/account/search-credits/',

        },
        {
          name: 'monthly plans',
          lnk: '/account/monthly-plans/',

        },
        {
          name: 'tinder blaster',
          lnk: '/account/tinder-blaster-credits/',

        },

        {
          name: 'listing locator',
          lnk: '/account/listing-locator/',

        },
      ],

      billing: [
        {
          name: 'payment method',
          lnk: '/account/billing/payment-methods/',

        },
        {
          name: 'invoices/ payments',
          lnk: '/account/billing/invoices/',

        },

      ],

      extras: [
        {
          name: 'pricing',
          lnk: '/account/plans-pricing/',

        },
        {
          name: 'e-books',
          lnk: '/account/e-books/',

        },
        {
          name: 'blog',
          lnk: '/account/blog/',

        },

      ],
      isActiveLinks: null,
      showBlock: false,
      isAct: false,

    }

  },

  mounted() {
    this.getUtm()
    this.guard()

  },
  methods: {

    shBlock() {
      this.showBlock = !this.showBlock
    },

    activeLink(linkIdent) {
      this.isActiveLinks = this.isActiveLinks === linkIdent ? null : linkIdent
    },

    addMobActive() {
      this.isActiveLinks = null;
    },
  },

}
