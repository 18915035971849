

export default {

  head() {
    const title = '404 Error — Page Not Found';

    return {
      title: title,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: title
        }
      ],
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToHome() {
      this.$router.push('/');
    }
  }
}
