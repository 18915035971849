import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './modules/user.module'
import searchReportModule from './modules/searchReport.module';
import stepsUserModule from './modules/stepsUser.module'

import faqModule from './modules/faq.module'

Vue.use(Vuex)

const store = () => new Vuex.Store({
  modules: {
    userModule,
    searchReportModule,
    faqModule,
    stepsUserModule
  },
  state: {
    stepsUser: {
      name: '',
      email: '',
      clientsName: '',
      clientsEmail: '',
      identityAddress: '',
      identityPhone: '',
      stateCode: '',
    },
    focused: false,
    mainStep: 1,
    isAccepted: false,
    isRemember: false,
    plans: [
      {
        name: 'DATING/ALTERNATIVE LIFESTYLE SITES',
        id: 1,
        lnk: '/products/search-on-dating-sites/',
      },
      {
        name: 'SOCIAL MEDIA & MOBILE CHAT/HOOK-UP APPS',
        id: 2,
        lnk: '/products/search-on-social-media-and-mobile-chat/'
      },
      {
        name: 'LISTING LOCATOR',
        id: 3,
        lnk: '/products/listing-locator/'
      },
      {
        name: 'LIVE ADULT STREAMS',
        id: 4,
        lnk: '/products/search-on-live-adult-streams/'
      },
      {
        name: 'XXX WEBSITES',
        id: 5,
        lnk: '/products/search-on-xxx-websites/',
      },
      {
        name: 'TINDER BLASTER',
        id: 6,
        lnk: '/products/search-on-tinder/'
      }
    ],
    locatorPlans: [
      {
        name: 'PEOPLE LOOKUP',
        id: 1,
      },
      {
        name: 'PHONE LOOKUP',
        id: 2,
      },
      {
        name: 'ADDRESS LOOKUP',
        id: 3,
      },
      {
        name: 'EMAIL LOOKUP',
        id: 4,
      }
    ],
    sex: [
      {
        name: 'MALE',
        id: 1,
      },
      {
        name: 'FEMALE',
        id: 2,
      }
    ],
    selectedPlan: 1,
    selectedLocatorPlan: 0,
    selectedSex: 0,
    isPlanSelected: false,
    isLocatorPlanSelected: false,
    isSexSelected: false,
    isValidateName: false,
    isValidateEmail: false,
    isValidateNameSubmit: false,
    isValidateEmailSubmit: false,
    tinderNormalAge: true,
    phoneNumber: '',
    countryCode: ''


  },

  getters: {
    isValidate: state => {
      return state.isValidateName && state.isValidateEmail;
    },

    submitValidate: state => {
      return state.isValidateNameSubmit && state.isValidateEmailSubmit;
    }
  },

  mutations: {

    setPhoneNumber(state, phoneNumber) {
      state.phoneNumber = phoneNumber;
    },
    setCountryCode(state, countryCode) {
      state.countryCode = countryCode;
    },    

    relocateFromSteps(state) {
        switch (state.selectedPlan) {
          case 0:
          case 1:
          case 3:
          case 4:
            this.$router.push('/account/search-credits/')
            break;

          case 2:
            this.$router.push('/account/listing-locator/')
            break;

          case 5 :
            this.$router.push('/account/tinder-blaster-credits/')
        }



    },

    tinderNormalAgeToggle(state, value) {
      state.tinderNormalAge = value
    },

    setUserName(state, value) {
      state.stepsUser.name = value
    },


    setUserEmail(state, value) {
      state.stepsUser.email = value
    },

    selectPlan(state, index) {
      state.stepsUser.mainPlan = index
      state.isPlanSelected = true
      state.selectedPlan = index
    },

    selectLocatorPlan(state, index) {
      state.stepsUser.locatorOpinion = index
      state.isLocatorPlanSelected = true
      state.selectedLocatorPlan = index
    },

    setUserPartnersIdentityName(state, value) {
      state.stepsUser.identityName = value
    },

    setUserPartnersIdentityAddress(state, value) {
      state.stepsUser.identityAddress = value
    },

    setUserPartnersIdentityPhone(state, value) {
      state.stepsUser.identityPhone = value
    },

    setUserPartnersStateCode(state, value) {
      state.stepsUser.stateCode = value
    },

    setUserPartnersAge(state, value) {
      if (value >=18 && value <=99) {state.tinderNormalAge = true} else {state.tinderNormalAge = false}
      state.stepsUser.partnersAge = value
    },

    selectSex(state, index) {
      state.stepsUser.partnersMale = index
      state.isSexSelected = true
      state.selectedSex = index
    },

    setClientsName(state, value) {
      state.stepsUser.clientsName = value
    },

    setClientsEmail(state, value) {
      state.stepsUser.clientsEmail = value
    },

    setFocus(state) {
      setTimeout(() => state.focused = true, 500)
      setTimeout(() => state.focused = false, 3000)
    },

    nextStep(state) {

      if (state.mainStep === 2 && state.selectedPlan === 5) {state.tinderNormalAge = false}



      if (this.getters.isValidate) {
        state.mainStep += 1
        if (state.mainStep > 2) {
          state.isPlanSelected = true
        }

      }
    },

    prevStep(state) {
      if (state.mainStep > 0)
      state.mainStep -= 1
    },

    accept(state) {
      state.isAccepted = !state.isAccepted

    },
    remember(state) {
      state.isRemember = !state.isRemember

    },

    acceptCancel(state) {
      state.isAccepted = false
    },



    validateName(state, payload) {
      state.isValidateName = payload
    },

    validateEmail(state, payload) {
      state.isValidateEmail = payload
    },

    validateNameSubmit(state, payload) {
      state.isValidateNameSubmit = payload
    },

    validateEmailSubmit(state, payload) {
      state.isValidateEmailSubmit = payload
    },
  },
  
  actions: {
    updatePhoneNumber({ commit }, { phoneNumber, countryCode }) {
      commit('setPhoneNumber', phoneNumber);
      commit('setCountryCode', countryCode);
    },
    getPhoneNumber({ state }) {
      return { phoneNumber: state.phoneNumber, countryCode: state.countryCode };
    }
  }  
 })

export default store
