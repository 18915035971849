
import Header from "../components/Header";
import FooterMain from "../components/FooterMain";
import authorization from "@/mixins/authorization";
export default {
  mixins: [authorization],
  mounted() {
    this.getUtm()
  },

  // head: {
  //
  //   script: [
  //     { type: 'text/javascript', src: '//web.webpushs.com/js/push/e450b2e3cc52ef2dc2ff524c37e28845_1.js'}
  //   ]
  // },


  components: { Header, FooterMain }
}
