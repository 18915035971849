export default {
    data() {
        return {
            middlePlans: [],


        }
    },
    methods: {


        // ecommercePush(plan_id, invoice_id, price) {
        //     //  plan_id - айди плана, invoice_id - айди транзакции, price - стоимость после применения купона
        //     if (plan_id && invoice_id && `${process.env.API_BASE_URL}` === 'https://api.ifindcheaters.com') {
        //         // if (plan_id && invoice_id) {
        //         let ecommerce = {
        //             'currencyCode': 'USD',
        //             'purchase': {
        //                 'actionField': {
        //                     'id': '',
        //                     'revenue': '',
        //                     'coupon': ''
        //                 },
        //                 'products': [{
        //                     'name': '',
        //                     'id': '',
        //                     'price': '',
        //                     'brand': 'iFindCheaters',
        //                     'category': '',
        //                     'quantity': 1
        //                 }]
        //             }
        //         }
        //         switch (plan_id) {
        //             case 2:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'TINDER BLASTER (3)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 14.99
        //                 ecommerce.purchase.products[0].category = 'TINDER SEARCH CREDITS'
        //                 break;

        //             case 3:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'TINDER BLASTER (5)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 19.99
        //                 ecommerce.purchase.products[0].category = 'TINDER SEARCH CREDITS'
        //                 break;

        //             case 4:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'TINDER BLASTER (10)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 29.99
        //                 ecommerce.purchase.products[0].category = 'TINDER SEARCH CREDITS'
        //                 break;

        //             case 5:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'FLEX (1)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 9.99
        //                 ecommerce.purchase.products[0].category = 'EMAIL/PROFILE SEARCH CREDIT'
        //                 break;

        //             case 6:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'FLEX (3)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 19.99
        //                 ecommerce.purchase.products[0].category = 'EMAIL/PROFILE SEARCH CREDIT'
        //                 break;

        //             case 7:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'FLEX (5)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 29.99
        //                 ecommerce.purchase.products[0].category = 'EMAIL/PROFILE SEARCH CREDIT'
        //                 break;

        //             case 8:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'LISTING LOCATOR (1)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 27.99
        //                 ecommerce.purchase.products[0].category = 'PEOPLE/PHONE/ADDRESS/EMAIL LOOKUP'
        //                 break;

        //             case 9:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'LISTING LOCATOR (3)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 15.99
        //                 ecommerce.purchase.products[0].category = 'PEOPLE/PHONE/ADDRESS/EMAIL LOOKUP'
        //                 break;

        //             case 10:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'LISTING LOCATOR (5)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 24.99
        //                 ecommerce.purchase.products[0].category = 'PEOPLE/PHONE/ADDRESS/EMAIL LOOKUP'
        //                 break;

        //             case 11:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'MONTHLY (2)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 49.99
        //                 ecommerce.purchase.products[0].category = 'DAILY EMAIL/PROFILE SEARCHES'
        //                 break;

        //             case 12:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'MONTHLY (3)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 75.99
        //                 ecommerce.purchase.products[0].category = 'DAILY EMAIL/PROFILE SEARCHES'
        //                 break;

        //             case 13:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'MONTHLY (4)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 99.99
        //                 ecommerce.purchase.products[0].category = 'DAILY EMAIL/PROFILE SEARCHES'
        //                 break;

        //             case 17:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'XXX FLEX (1)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 14.99
        //                 ecommerce.purchase.products[0].category = 'EMAIL/PROFILE SEARCH CREDIT'
        //                 break;

        //             case 18:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'XXX FLEX (3)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 24.99
        //                 ecommerce.purchase.products[0].category = 'EMAIL/PROFILE SEARCH CREDIT'
        //                 break;

        //             case 19:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'XXX FLEX (5)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 34.99
        //                 ecommerce.purchase.products[0].category = 'EMAIL/PROFILE SEARCH CREDIT'
        //                 break;

        //             case 20:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'XXX MONTHLY (2)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 49.99
        //                 ecommerce.purchase.products[0].category = 'DAILY EMAIL/PROFILE SEARCHES'
        //                 break;

        //             case 21:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'XXX MONTHLY (3)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 75.99
        //                 ecommerce.purchase.products[0].category = 'DAILY EMAIL/PROFILE SEARCHES'
        //                 break;

        //             case 22:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'XXX MONTHLY (4)'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 99.99
        //                 ecommerce.purchase.products[0].category = 'DAILY EMAIL/PROFILE SEARCHES'
        //                 break;

        //             case 26:
        //                 ecommerce.purchase.actionField.id = invoice_id;
        //                 ecommerce.purchase.actionField.revenue = price;
        //                 ecommerce.purchase.actionField.coupon = this.coupon_code ? this.coupon_code : this.$store.state.userModule.coupon ? this.$store.state.userModule.coupon : '';

        //                 ecommerce.purchase.products[0].name = 'ULTIMATE 60'
        //                 ecommerce.purchase.products[0].id = plan_id
        //                 ecommerce.purchase.products[0].price = 99.99
        //                 ecommerce.purchase.products[0].category = 'SEARCH CREDITS BUNDLED ACROSS ALL OUR SERVICES'
        //                 break;

        //             default:
        //                 ecommerce.purchase.actionField.id = '';
        //                 ecommerce.purchase.actionField.revenue = '';
        //                 ecommerce.purchase.actionField.coupon = '';

        //                 ecommerce.purchase.products[0].name = ''
        //                 ecommerce.purchase.products[0].id = ''
        //                 ecommerce.purchase.products[0].price = ''
        //                 ecommerce.purchase.products[0].category = ''
        //                 break;
        //         }

        //         // this.$gtag('event', 'payment-button')
        //             // this.$gtag('event', 'payment-button', {'invoice_id': invoice_id})

        //         console.log('ecommerce ', ecommerce)




        //         // console.log('ecommerce ', ecommerce)
        //         window.dataLayer = window.dataLayer || [];
        //         dataLayer.push({

        //             ecommerce,

        //             'event': 'gtm-ee-event',
        //             'gtm-ee-event-category': 'Enhanced Ecommerce',
        //             'gtm-ee-event-action': 'Purchase',
        //             'gtm-ee-event-non-interaction': 'False',
        //         });

        //     }
        // },



        normalizePlans() {
            let self = this
            let temp = []
            self.$store.state.userModule.userPlansArray.map(function(item) {
                if (item && item.name) {
                    temp.push(item)
                }
            })

            this.middlePlans = temp

        },

        getPlans() {
            let self = this;
            this.$axios.get(`${process.env.API_BASE_URL}/api/account/plans`, {
                    headers: {
                        Authorization: `bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(function(response) {
                    self.$store.commit('userModule/SET_USERPLANS', response.data)



                    self.$store.commit('userModule/SET_ARRAY_USERPLANS', Object.values(response.data))
                    self.normalizePlans()

                })
                .catch(function(error) {
                    console.log('error: ' + error);
                });
        },

        getUser() {
            let self = this;
            this.$axios.setHeader('Authorization', `bearer ${localStorage.getItem('token')}`, ['post'])
            this.$axios.post(`${process.env.API_BASE_URL}/api/auth/me`, {
                    token: `${localStorage.getItem('token')}`
                })
                .then(function(response) {
                    self.$store.commit('userModule/SET_USERDATA', response.data)

                })
                .catch(function(error) {
                    console.log('error: ' + error);
                    self.$store.commit('userModule/LOGOUT')
                });
        },

        getReports() {
            let self = this;
            this.$axios.get(`${process.env.API_BASE_URL}/api/account/reports`, {
                    headers: {
                        Authorization: `bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(function(response) {
                    self.$store.commit('searchReportModule/SET_SEARCH_REPORT', response.data)

                })
                .catch(function(error) {
                    console.log('error: ' + error);
                });
        },


        async guard() {
            try {
                this.$axios.setHeader('Authorization', `bearer ${localStorage.getItem('token')}`, ['post']);
                const response = await this.$axios.post(`${process.env.API_BASE_URL}/api/auth/me`, {
                    token: `${localStorage.getItem('token')}`
                });

                if (response.data.error === 'Unauthenticated.') {
                    this.$store.commit('userModule/LOGOUT');
                    throw new Error('Unauthenticated');
                } else if (response.data.refresh_token_time < 600) {
                    localStorage.removeItem('redirectUrl');
                    this.refresh();
                }

                return response.data;
            } catch (error) {
                console.error('error: ' + error);
                this.$store.commit('userModule/LOGOUT');
                throw error;
            }
        },


        getUtm() {
            if (this.$route.query.utm_campaign ||
                this.$route.query.utm_content ||
                this.$route.query.utm_funnel ||
                this.$route.query.utm_medium ||
                this.$route.query.utm_source ||
                this.$route.query.utm_term
            ) {
                this.$store.commit('userModule/SET_UTM', this.$route.query)
                localStorage.setItem('redirectUrl', this.$route.path);
            }



            if (this.$route.query.coupon_id) {
                let self = this
                this.$axios.setHeader('Authorization', `bearer ${localStorage.getItem('token')}`, ['post'])
                this.$axios.post(`${process.env.API_BASE_URL}/api/get_coupon`, {
                        id: this.$route.query.coupon_id
                    })
                    .then(function(response) {
                        if (response.data.code) {

                            self.$store.commit('userModule/SET_COUPON', response.data.code)
                                // console.log('coupon-code ', self.$store.state.userModule.coupon)
                        }
                    })

                .catch(function(error) {
                    self.isError = true
                    console.log('error: ' + error);
                });
            }
            // console.log('route ',this.$store.state.userModule.utm)
        },

        sendUtm() {
            let self = this
            this.$axios.setHeader('Authorization', `bearer ${localStorage.getItem('token')}`, ['post'])
            this.$axios.post(`${process.env.API_BASE_URL}/api/account/set_cookie`, {

                    plan_id: this.plan_id,
                    coupon_code: this.coupon_code,
                    gclid: this.$store.state.userModule.utm.gclid ? this.$store.state.userModule.utm.gclid : '',
                    referral: this.$store.state.userModule.utm.referral ? this.$store.state.userModule.utm.referral : '',
                    utm_funnel: this.$store.state.userModule.utm.utm_funnel ? this.$store.state.userModule.utm.utm_funnel : '',
                    utm_source: this.$store.state.userModule.utm.utm_source ? this.$store.state.userModule.utm.utm_source : '',
                    utm_campaign: this.$store.state.userModule.utm.utm_campaign ? this.$store.state.userModule.utm.utm_campaign : '',
                    utm_medium: this.$store.state.userModule.utm.utm_medium ? this.$store.state.userModule.utm.utm_medium : '',
                    utm_term: this.$store.state.userModule.utm.utm_term ? this.$store.state.userModule.utm.utm_term : '',
                    utm_content: this.$store.state.userModule.utm.utm_content ? this.$store.state.userModule.utm.utm_content : '',
                })
                .then(function(response) {
                    // console.log("UTM =", response.data)
                    // console.log("sendUTM script.js")
                    self.$store.commit('userModule/DEL_UTM')
                    self.$store.commit('userModule/DEL_COUPON')
                    self.coupon_code = ''
                })
                .catch(function(error) {
                    console.log('error: ' + error);
                    self.spinnerOn = false
                    self.showModal = false
                });
        },

        redirectToBillingIfPaymentError(responseData) {
            if (responseData.error &&
                (responseData.error === 'The payment attempt failed because of an invalid payment method.' ||
                    responseData.error === 'This customer has no attached payment source or default payment method. Please consider adding a default payment method. For more information, visit https://stripe.com/docs/billing/subscriptions/payment-methods-setting#payment-method-priority.' ||
                    responseData.error === 'User is not a Stripe customer yet. See the createAsStripeCustomer method.')
            ) {
                setTimeout(() => this.$router.push('/account/billing/payment-methods/'), 2000)
            }
        },

        refresh() {
            let self = this
            this.$axios.post(`${process.env.API_BASE_URL}/api/auth/refresh/`, {
                    token: `${localStorage.getItem('token')}`
                })
                .then(function(response) {


                    if (response.data.access_token) {
                        localStorage.setItem('token', response.data.access_token);


                        self.$store.commit('userModule/SET_IS_LOGGED')

                        self.$router.push('/account/dashboard/');
                    }
                })

            .catch(function(error) {
                self.isError = true
                console.log('error: ' + error);
            });
        }
    },
    computed: {
        isToken() {
            if (process.browser) {
                return !!localStorage.getItem('token')
            }
        },
    }

}