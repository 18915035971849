export default function ({ app }, inject) {
  let deferredPrompt = null;
  let showInstallButton = localStorage.getItem("showInstallButton") === "true";
  let closeInstallButton = localStorage.getItem("closeAppBanner") === "true";

  // Checking if the application is a PWA only if we are in a browser
  const isAppInstalled = process.browser && (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://'));

  function handleBeforeInstallPrompt(event) {
    event.preventDefault();
    deferredPrompt = event;
    showInstallButton = !closeInstallButton && !isAppInstalled;
    localStorage.setItem("showInstallButton", showInstallButton ? "true" : "false");
  }

  const bannerPlugin = {
    get deferredPrompt() {
      return deferredPrompt;
    },
    get showInstallButton() {
      return showInstallButton;
    },
    get closeInstallButton() {
      return closeInstallButton;
    },
    setCloseInstallButton(value) {
      closeInstallButton = value;
      localStorage.setItem("closeAppBanner", value ? "true" : "false");
      showInstallButton = !closeInstallButton && !isAppInstalled;
      localStorage.setItem("showInstallButton", showInstallButton ? "true" : "false");

    },
    promptInstall() {
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            alert("The application has been installed!");
          }
          deferredPrompt = null;
        });
      }
    },
  };

  if (process.browser) {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
  }

  inject("bannerPlugin", bannerPlugin);
}
