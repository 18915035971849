

import ClickOutside from 'vue-click-outside'

export default {
  props: ["items", "title", "icon", "showBlock", "isActiveLinks", "ver", "check"],
  data() {
    return {
      // showBlockLocal: this.showBlock,
      isActLinks: '',
      opened: false,
      actLinksLocal: null
    }
  },

  mounted() {
    this.popupItem = this.$el
  },

  directives: {
    ClickOutside
  },
  
  watch: {
    opened() {
        this.$emit('mobactive')
    }
  },
  
  methods: {
    toggle () {
      this.opened = true
    },
 
    hide () {
      this.opened = false
    },     

    // hideBlock() {
    //   // this.showBlockLocal = false;
    //   // this.isActLinks = isActiveLinks;
    //   this.$emit('mobactive')
    // }
  },
};
