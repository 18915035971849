import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {
      userPlans: null,
      userData: null,
      isLogged: false,
      getAuthToken: '',
      userPlansArray: [],
      utm: null,
      coupon: null,
    };
  },



mutations: {

  SET_UTM(state, utm) {
    state.utm = utm
  },

  SET_COUPON(state, coupon) {
    state.coupon = coupon
  },

  DEL_COUPON(state) {
    state.coupon = null
  },

  DEL_UTM(state) {
    state.utm = null
  },

  SET_IS_LOGGED(state) {
    state.isLogged = true
  },

  SET_USERPLANS(state, value) {
    state.userPlans = value
  },

  SET_ARRAY_USERPLANS(state, value) {
    state.userPlansArray = value
  },

  SET_USERDATA(state, value) {
    state.userData = value
  },

  LOGOUT(state) {
    state.isLogged = false
    state.userData = null
    this.$router.push('/auth/login/')
    this.$axios.post(`${process.env.API_BASE_URL}/api/auth/logout/`, {
      token: `${localStorage.getItem('token')}`
    })
      .then(function (response) {
        localStorage.removeItem('token');
        localStorage.removeItem('userID');
      })
      .catch(function (error) {
        console.log('error: ' + error);

      });

  },

  REFRESH(state) {
    let self = this;
    // if (this.$router.app.context.from.name !== 'auth-login') {
      this.$axios.setHeader('Authorization', `bearer ${localStorage.getItem('token')}`, ['post'])
      this.$axios.post(`${process.env.API_BASE_URL}/api/auth/refresh`)
        .then(function (response) {

          if (response.data.access_token) {
            localStorage.setItem('token ', response.data.access_token)

            self.commit('userModule/SET_IS_LOGGED')
          } else {
            self.commit('userModule/LOGOUT')
          }
        })

        .catch(function (error) {
          console.log('error: ' + error);
          self.commit('userModule/LOGOUT')
          // self.$store.commit('LOGOUT')
        });
    // }

  },

 }

};
