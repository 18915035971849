import { loadStripe } from '@stripe/stripe-js';

// Инициализируем Stripe.js с публичным ключом API
const stripePromise = loadStripe(process.env.RADAR_SESSION_KEY);

// Функция для создания Radar Session
async function createRadarSession() {
    try {
        const stripe = await stripePromise;
        const { radarSession, error } = await stripe.createRadarSession();

        if (error) {
            console.error(error);
            return { error };
        } else {
            return { radarSession };
        }
    } catch (error) {
        console.error(error);
        return { error };
    }
}

// Экспортируем функцию createRadarSession как плагин
export default function({ app }, inject) {
        // console.log('Injecting createRadarSession');
        inject('createRadarSession', createRadarSession); 
}
