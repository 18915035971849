import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    userReport: null,
    scanResult: '',
    scanID: '',
  }),

  mutations: {

    SET_SEARCH_REPORT(state, value) {
      state.userReport = value
    },

    GET_SCAN_REPORT(state, value) {
      state.scanResult = value
    },

    SET_SCAN_ID(state, value) {
      state.scanID = value
    }
  },



};
