import { render, staticRenderFns } from "./clientLayout.vue?vue&type=template&id=29143fee"
import script from "./clientLayout.vue?vue&type=script&lang=js"
export * from "./clientLayout.vue?vue&type=script&lang=js"
import style0 from "./clientLayout.vue?vue&type=style&index=0&id=29143fee&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/app/components/Header.vue').default,FooterMain: require('/app/components/FooterMain.vue').default})
