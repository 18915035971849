import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e9b98470 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _3c915630 = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _435598f6 = () => interopDefault(import('../pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _54991054 = () => interopDefault(import('../pages/ebook.vue' /* webpackChunkName: "pages/ebook" */))
const _8d10a152 = () => interopDefault(import('../pages/error500.vue' /* webpackChunkName: "pages/error500" */))
const _16a1af70 = () => interopDefault(import('../pages/example.vue' /* webpackChunkName: "pages/example" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _6b1e0dee = () => interopDefault(import('../pages/gdpr.vue' /* webpackChunkName: "pages/gdpr" */))
const _74f0ecb2 = () => interopDefault(import('../pages/our-missions.vue' /* webpackChunkName: "pages/our-missions" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _edd6cea6 = () => interopDefault(import('../pages/products-services.vue' /* webpackChunkName: "pages/products-services" */))
const _21b2b8dd = () => interopDefault(import('../pages/remove-info.vue' /* webpackChunkName: "pages/remove-info" */))
const _07f8a58a = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _44d0e6c4 = () => interopDefault(import('../pages/testimonials.vue' /* webpackChunkName: "pages/testimonials" */))
const _a63e13b8 = () => interopDefault(import('../pages/under-maintenance.vue' /* webpackChunkName: "pages/under-maintenance" */))
const _52a39f2e = () => interopDefault(import('../pages/account/blog.vue' /* webpackChunkName: "pages/account/blog" */))
const _e5e12490 = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _faff7334 = () => interopDefault(import('../pages/account/e-books.vue' /* webpackChunkName: "pages/account/e-books" */))
const _01c4ac98 = () => interopDefault(import('../pages/account/listing-locator/index.vue' /* webpackChunkName: "pages/account/listing-locator/index" */))
const _5fae3a64 = () => interopDefault(import('../pages/account/monthly-plans.vue' /* webpackChunkName: "pages/account/monthly-plans" */))
const _649da80c = () => interopDefault(import('../pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _2a83b107 = () => interopDefault(import('../pages/account/plans-pricing.vue' /* webpackChunkName: "pages/account/plans-pricing" */))
const _6438ad4d = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _e6157afe = () => interopDefault(import('../pages/account/search-credits.vue' /* webpackChunkName: "pages/account/search-credits" */))
const _cda260c6 = () => interopDefault(import('../pages/account/search-report.vue' /* webpackChunkName: "pages/account/search-report" */))
const _45fd8cee = () => interopDefault(import('../pages/account/tinder-blaster-credits.vue' /* webpackChunkName: "pages/account/tinder-blaster-credits" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _33eac2b0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _6774179a = () => interopDefault(import('../pages/products/find-someone-cheating-online-for-free.vue' /* webpackChunkName: "pages/products/find-someone-cheating-online-for-free" */))
const _15c5868c = () => interopDefault(import('../pages/products/listing-locator.vue' /* webpackChunkName: "pages/products/listing-locator" */))
const _5ed19678 = () => interopDefault(import('../pages/products/search-on-dating-sites.vue' /* webpackChunkName: "pages/products/search-on-dating-sites" */))
const _76bd3ed9 = () => interopDefault(import('../pages/products/search-on-live-adult-streams.vue' /* webpackChunkName: "pages/products/search-on-live-adult-streams" */))
const _12141d04 = () => interopDefault(import('../pages/products/search-on-social-media-and-mobile-chat.vue' /* webpackChunkName: "pages/products/search-on-social-media-and-mobile-chat" */))
const _0a75251c = () => interopDefault(import('../pages/products/search-on-tinder.vue' /* webpackChunkName: "pages/products/search-on-tinder" */))
const _5bc2e6f2 = () => interopDefault(import('../pages/products/search-on-xxx-websites.vue' /* webpackChunkName: "pages/products/search-on-xxx-websites" */))
const _5faf15cc = () => interopDefault(import('../pages/account/billing/cancel.vue' /* webpackChunkName: "pages/account/billing/cancel" */))
const _47b79cf4 = () => interopDefault(import('../pages/account/billing/invoices.vue' /* webpackChunkName: "pages/account/billing/invoices" */))
const _1bc887fb = () => interopDefault(import('../pages/account/billing/payment-methods.vue' /* webpackChunkName: "pages/account/billing/payment-methods" */))
const _bef487ac = () => interopDefault(import('../pages/account/subscriptions/manage.vue' /* webpackChunkName: "pages/account/subscriptions/manage" */))
const _f74d3890 = () => interopDefault(import('../pages/auth/password/email/index.vue' /* webpackChunkName: "pages/auth/password/email/index" */))
const _77fea7a0 = () => interopDefault(import('../pages/api/auth/login/facebook/callback.vue' /* webpackChunkName: "pages/api/auth/login/facebook/callback" */))
const _601794e6 = () => interopDefault(import('../pages/api/auth/login/google/callback.vue' /* webpackChunkName: "pages/api/auth/login/google/callback" */))
const _3d8e8c68 = () => interopDefault(import('../pages/api/auth/login/linkedin/callback.vue' /* webpackChunkName: "pages/api/auth/login/linkedin/callback" */))
const _0996dec2 = () => interopDefault(import('../pages/api/auth/login/twitter/callback.vue' /* webpackChunkName: "pages/api/auth/login/twitter/callback" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _32c623cd = () => interopDefault(import('../pages/account/subscriptions/payments/view/_slug.vue' /* webpackChunkName: "pages/account/subscriptions/payments/view/_slug" */))
const _516e5063 = () => interopDefault(import('../pages/auth/password/reset/_slug.vue' /* webpackChunkName: "pages/auth/password/reset/_slug" */))
const _0531ef28 = () => interopDefault(import('../pages/account/listing-locator/_slug.vue' /* webpackChunkName: "pages/account/listing-locator/_slug" */))
const _7b1d1600 = () => interopDefault(import('../pages/account/verify/_slug.vue' /* webpackChunkName: "pages/account/verify/_slug" */))
const _4ff73f59 = () => interopDefault(import('../pages/blog/author/_slug.vue' /* webpackChunkName: "pages/blog/author/_slug" */))
const _28b03768 = () => interopDefault(import('../pages/blog/category/_slug.vue' /* webpackChunkName: "pages/blog/category/_slug" */))
const _59209512 = () => interopDefault(import('../pages/blog/tag/_slug.vue' /* webpackChunkName: "pages/blog/tag/_slug" */))
const _09a00336 = () => interopDefault(import('../pages/tinder/search/_firstSearch/index.vue' /* webpackChunkName: "pages/tinder/search/_firstSearch/index" */))
const _07e961ee = () => interopDefault(import('../pages/tinder/search/_firstSearch/_slug.vue' /* webpackChunkName: "pages/tinder/search/_firstSearch/_slug" */))
const _9d03b872 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _15b1b63c = () => interopDefault(import('../pages/scan/_slug.vue' /* webpackChunkName: "pages/scan/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _e9b98470,
    name: "about-us"
  }, {
    path: "/blog",
    component: _999675e2,
    name: "blog"
  }, {
    path: "/career",
    component: _3c915630,
    name: "career"
  }, {
    path: "/contact",
    component: _e64111b4,
    name: "contact"
  }, {
    path: "/disclaimer",
    component: _435598f6,
    name: "disclaimer"
  }, {
    path: "/ebook",
    component: _54991054,
    name: "ebook"
  }, {
    path: "/error500",
    component: _8d10a152,
    name: "error500"
  }, {
    path: "/example",
    component: _16a1af70,
    name: "example"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq"
  }, {
    path: "/gdpr",
    component: _6b1e0dee,
    name: "gdpr"
  }, {
    path: "/our-missions",
    component: _74f0ecb2,
    name: "our-missions"
  }, {
    path: "/privacy-policy",
    component: _1b596bfe,
    name: "privacy-policy"
  }, {
    path: "/products",
    component: _6a13e351,
    name: "products"
  }, {
    path: "/products-services",
    component: _edd6cea6,
    name: "products-services"
  }, {
    path: "/remove-info",
    component: _21b2b8dd,
    name: "remove-info"
  }, {
    path: "/sitemap",
    component: _07f8a58a,
    name: "sitemap"
  }, {
    path: "/terms",
    component: _500e56e6,
    name: "terms"
  }, {
    path: "/testimonials",
    component: _44d0e6c4,
    name: "testimonials"
  }, {
    path: "/under-maintenance",
    component: _a63e13b8,
    name: "under-maintenance"
  }, {
    path: "/account/blog",
    component: _52a39f2e,
    name: "account-blog"
  }, {
    path: "/account/dashboard",
    component: _e5e12490,
    name: "account-dashboard"
  }, {
    path: "/account/e-books",
    component: _faff7334,
    name: "account-e-books"
  }, {
    path: "/account/listing-locator",
    component: _01c4ac98,
    name: "account-listing-locator"
  }, {
    path: "/account/monthly-plans",
    component: _5fae3a64,
    name: "account-monthly-plans"
  }, {
    path: "/account/notifications",
    component: _649da80c,
    name: "account-notifications"
  }, {
    path: "/account/plans-pricing",
    component: _2a83b107,
    name: "account-plans-pricing"
  }, {
    path: "/account/profile",
    component: _6438ad4d,
    name: "account-profile"
  }, {
    path: "/account/search-credits",
    component: _e6157afe,
    name: "account-search-credits"
  }, {
    path: "/account/search-report",
    component: _cda260c6,
    name: "account-search-report"
  }, {
    path: "/account/tinder-blaster-credits",
    component: _45fd8cee,
    name: "account-tinder-blaster-credits"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _33eac2b0,
    name: "auth-register"
  }, {
    path: "/products/find-someone-cheating-online-for-free",
    component: _6774179a,
    name: "products-find-someone-cheating-online-for-free"
  }, {
    path: "/products/listing-locator",
    component: _15c5868c,
    name: "products-listing-locator"
  }, {
    path: "/products/search-on-dating-sites",
    component: _5ed19678,
    name: "products-search-on-dating-sites"
  }, {
    path: "/products/search-on-live-adult-streams",
    component: _76bd3ed9,
    name: "products-search-on-live-adult-streams"
  }, {
    path: "/products/search-on-social-media-and-mobile-chat",
    component: _12141d04,
    name: "products-search-on-social-media-and-mobile-chat"
  }, {
    path: "/products/search-on-tinder",
    component: _0a75251c,
    name: "products-search-on-tinder"
  }, {
    path: "/products/search-on-xxx-websites",
    component: _5bc2e6f2,
    name: "products-search-on-xxx-websites"
  }, {
    path: "/account/billing/cancel",
    component: _5faf15cc,
    name: "account-billing-cancel"
  }, {
    path: "/account/billing/invoices",
    component: _47b79cf4,
    name: "account-billing-invoices"
  }, {
    path: "/account/billing/payment-methods",
    component: _1bc887fb,
    name: "account-billing-payment-methods"
  }, {
    path: "/account/subscriptions/manage",
    component: _bef487ac,
    name: "account-subscriptions-manage"
  }, {
    path: "/auth/password/email",
    component: _f74d3890,
    name: "auth-password-email"
  }, {
    path: "/api/auth/login/facebook/callback",
    component: _77fea7a0,
    name: "api-auth-login-facebook-callback"
  }, {
    path: "/api/auth/login/google/callback",
    component: _601794e6,
    name: "api-auth-login-google-callback"
  }, {
    path: "/api/auth/login/linkedin/callback",
    component: _3d8e8c68,
    name: "api-auth-login-linkedin-callback"
  }, {
    path: "/api/auth/login/twitter/callback",
    component: _0996dec2,
    name: "api-auth-login-twitter-callback"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/account/subscriptions/payments/view/:slug?",
    component: _32c623cd,
    name: "account-subscriptions-payments-view-slug"
  }, {
    path: "/auth/password/reset/:slug?",
    component: _516e5063,
    name: "auth-password-reset-slug"
  }, {
    path: "/account/listing-locator/:slug?",
    component: _0531ef28,
    name: "account-listing-locator-slug"
  }, {
    path: "/account/verify/:slug?",
    component: _7b1d1600,
    name: "account-verify-slug"
  }, {
    path: "/blog/author/:slug?",
    component: _4ff73f59,
    name: "blog-author-slug"
  }, {
    path: "/blog/category/:slug?",
    component: _28b03768,
    name: "blog-category-slug"
  }, {
    path: "/blog/tag/:slug?",
    component: _59209512,
    name: "blog-tag-slug"
  }, {
    path: "/tinder/search/:firstSearch",
    component: _09a00336,
    name: "tinder-search-firstSearch"
  }, {
    path: "/tinder/search/:firstSearch?/:slug",
    component: _07e961ee,
    name: "tinder-search-firstSearch-slug"
  }, {
    path: "/blog/:slug",
    component: _9d03b872,
    name: "blog-slug"
  }, {
    path: "/scan/:slug?",
    component: _15b1b63c,
    name: "scan-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
