const state = () => ({
  question: 1
});

const mutations = {
  setQuestion(state, value) {
    state.question = value
  },
};


export default {
  namespaced: true,
  state,
  mutations,
};
