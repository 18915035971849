// plugins/ipPlugin.js
import axios from 'axios';

const IP_API_URL = 'https://api64.ipify.org?format=json';

export default async ({ app }, inject) => {
  const getIpAddress = async () => {
    try {
      const response = await axios.get(IP_API_URL);
      return response.data.ip;
    } catch (error) {
      console.error('Failed to fetch IP address:', error);
      return null;
    }
  };

  inject('getIpAddress', getIpAddress);
};
