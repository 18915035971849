
export default {

  data() {
    return {
      isMobile: false,
      isMob576: false,
      isMob768: false,
      utmParams: "",
      endDate: "",
    };
  },

  mounted() {
    this.mobile()
    this.href = window.location.href;
    this.search = window.location.search;
    /* Empty array for storing UTM tags, split by "&" */
    if (this.href.indexOf("?") != -1) {
      this.href = this.href.slice(
        0,
        this.href.indexOf("?")
      ); /* Page address without UTML tail */
      this.search = this.search.slice(1); /* UTM tail no question */
      this.utmArray = this.search.split("&"); /* Array of UTM labels (key = value) */
      this.utmParams = this.search;
      localStorage.utmParams = this.utmParams;
    }

    //Timer Date 24 hours
    this.endDate = localStorage.endDate;
    if (!this.endDate) {
      this.t = new Date();
      this.end = this.t.getTime() + 24 * 60 * 60 * 1000;
      this.endDate = this.end;
      localStorage.endDate = this.endDate;
    }
  },
  methods: {
    mobile(){

      this.isMobile = window.innerWidth <=375;
      this.isMob576 = window.innerWidth <=576;
      this.isMob768 = window.innerWidth <=768;
    },
  },

  created() {



    if (process.browser) {

      window.addEventListener(`resize`, event => {
        this.mobile()
      }, false);

    }

  }
};
