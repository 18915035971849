
import lazyload from "@/directives/lazyload";

import blog1 from "@/assets/images/main/footer/blog1.png"
import blog2 from "@/assets/images/main/footer/blog2.png"
import blog3 from "@/assets/images/main/footer/blog3.png"

import facebook from "@/assets/images/main/example/social/logo-social/facebook-logo.png"
import instagram from "@/assets/images/main/example/social/logo-social/instagram-logo.png"
import linkedin from "@/assets/images/main/example/social/logo-social/linkedin-logo.png"
import pinterest from "@/assets/images/main/example/social/logo-social/pinterest-logo.png"
import twitter from "@/assets/images/main/example/social/logo-social/twitter-logo.png"
import youtube from "@/assets/images/main/example/social/logo-social/youtube-logo.png"
import ifcblog from "@/assets/images/main/example/social/logo-social/ifc-blog.png"

import isMob576 from "@/mixins/isMob";

export default {
  data() {
    return {
      info: null,
      images: {
        facebook,
        instagram,
        linkedin,
        pinterest,
        twitter,
        youtube,
        ifcblog
      },
      products: [
        {
          name: 'Dating/Alternative LifeStyles Sites',
          lnk: '/products/search-on-dating-sites/'
        },
        {
          name: 'Social Media & Mobile Chat/Hook-Up Apps',
          lnk: '/products/search-on-social-media-and-mobile-chat/'
        },
        {
          name: 'Listing Locator',
          lnk: '/products/listing-locator/'
        },
        {
          name: 'Live Adult Streams',
          lnk: '/products/search-on-live-adult-streams/'
        },
        {
          name: 'XXX Websites',
          lnk: '/products/search-on-xxx-websites/'
        },
        {
          name: 'Tinder Blaster',
          lnk: '/products/search-on-tinder/'
        },

      ],
      quickLinks: [
        {
          name: 'Sample Reports',
          lnk: '/example/'
        },
        {
          name: 'Our Missions',
          lnk: '/our-missions/'
        },
        {
          name: 'Pricing',
          lnk: '/products-services/'
        },
        {
          name: 'Testimonials',
          lnk: '/testimonials/'
        },
        {
          name: 'About Us',
          lnk: '/about-us/'
        },
        {
          name: 'Contact',
          lnk: '/contact/'
        },
        {
          name: 'E-Books',
          lnk: '/ebook/'
        },
        {
          name: 'Careers',
          lnk: '/career/'
        },
        {
          name: 'Blog',
          lnk: '/blog/'
        },

      ],
      importantLinks: [
        {
          name: 'Terms & Conditions',
          lnk: '/terms/'
        },
        {
          name: 'Privacy Policy',
          lnk: '/privacy-policy/'
        },
        {
          name: 'GDPR',
          lnk: '/gdpr/'
        },
        {
          name: 'FAQs',
          lnk: '/faq/'
        },
        {
          name: 'Remove My Info',
          lnk: '/remove-info/'
        },
        {
          name: 'Disclaimer',
          lnk: '/disclaimer/'
        },
        {
          name: 'Site Map',
          lnk: '/sitemap/'
        },

      ],

      blog: [
        {
          img: blog1,
          text: 'the truth about infidelity: what <br> defines a cheater.'
        },
        {
          img: blog2,
          text: 'the truth about infidelity: what <br> defines a cheater.'
        },
        {
          img: blog3,
          text: 'the truth about infidelity: what <br> defines a cheater.'
        },

      ],
      nowDate: new Date(),
    };
  },
  directives: {
    lazyload,
  },
  mixins: [isMob576],
  mounted() {
    this.$axios
      .get( `${process.env.BLOG_API_BASE_URL}/api/posts/latest`)
      .then(response => {
        this.info = response.data
      })
      .catch(function (error) {
        console.log('error: ' + error);
      });
  },

  methods: {
    getEncodedEmail(email) {
      const encodedEmail = encodeURIComponent(email);
      return `mailto:${encodedEmail}`;
    },
  },
  computed: {
    visibleItems() {
      if (this.isMob576) {
        return this.info.data.slice(0, 2);
      } else {
        return this.info.data;
      }
    },
    nowYear() {
      return this.nowDate.getFullYear()
    }
  }
};
